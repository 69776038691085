import React, { useContext,useEffect } from 'react';
import { Link,useNavigate  } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton'; 
import AuthContext from '../helpers/context/AuthContext'; 
import logo from "../ce.svg";
import logo2 from '../ssel.png';

function Topbar() {
  const { isAuthenticated,user,logout } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    console.log('Auth status updated:', isAuthenticated, 'User:', user);
  }, [isAuthenticated, user]);
  const handleLogout = (e) => {
    if (e) e.preventDefault();
    //console.log('logout');
    logout();
          navigate('/'); // Replace '/login' with your desired path
  }
  return (
    <Navbar expand="lg" className="topbar">
      <Container className="p-0 mt-0 mb-0">
        <Navbar.Brand href="#home" className="col-6"><img
          src={logo}
          alt="Smart IoT"
          width="200"
          height="100"
          className="d-inline-block align-top"
        />
        {' '}
        </Navbar.Brand>
        
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
      {isAuthenticated ? (
        <>
          <DropdownButton id="dropdown-basic-button" title={user?.fullName}>
            <Dropdown.Item as={Link} to="/profile">
              Profile
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/">
              Dashboard
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogout}>
              Logout
            </Dropdown.Item>
          </DropdownButton>   

        </>
      ) :''}
    </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  
  );
}

export default Topbar;
